/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	GET_HISTORY_DATA,
	INIT_JOURNAL_DATA,
	SET_JOURNAL_DATA,
} from "./actions";
import {
	MOVE_MODE,
} from "./consts";

const initState = {
	projectsList: [],
	nonProjectsList: [],
	projects: null,
	vacations: null,
	tasks: null,
	activities: null,
	worklogs: null,
	totalHoursByDate: {},
	projectsTotalHours: {},
	allProjectsTotalHours: {},
	allNonProjectsTotalHours: {},
	allNonProjectsAutofillTotalHours: {},
	allProjectsAutofillTotalHours: {},
	allAutofillTotalHours: {},
	vacTotalHours: {},
	weeks: [],
	user: {},
	vacationsWorklogsExists: false,
	hasPredictions: false,
	history: [],
	position: -1,
	moveMode: MOVE_MODE.NONE,
	projectIdInAction: null,

	calendar: [],
	norm: {},
	validationRules: [],
	changed: false,

	markupStatuses: null,
	markupModeActive: false,
};

export default function dashboard(
	// It is a correct approach for Redux reducers.
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state = initState,
	action,
) {
	const {
		history,
		position,
	} = state;
	let data = null;

	switch (action.type) {
		case INIT_JOURNAL_DATA: {
			return {
				...action.payload,
				history: [
					{
						...action.payload,
					},
				],
				position: 0,
			};
		}

		case SET_JOURNAL_DATA: {
			data = history[position];

			const newState = {
				...data,
				...action.payload,
			};
			const hist = [
				...history.slice(0, position + 1),
				newState,
			];

			const res = {
				...newState,
				history: hist,
				position: hist.length - 1,
			};

			return res;
		}

		case GET_HISTORY_DATA: {
			const newPosition = position + action.payload.step;

			if (
				newPosition > -1
				&& position < history.length
			) {
				data = history[newPosition];

				return {
					...data,
					position: newPosition,
					history,
				};
			}

			return state;
		}

		default: {
			return state;
		}
	}
}
