/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	EMPLOYEE_DASHBOARD_ADD_EMPLOYEES,
	EMPLOYEE_DASHBOARD_SET_EMPLOYEES,
} from "./actions";

const defaultState = {
	fullList: [],
	filteredList: [],
	viewList: [],
	unitHeads: [],
	locations: [],
};

export default function dashboard(
	// It is a correct approach for Redux reducers.
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state = defaultState,
	action,
) {
	switch (action.type) {
		case EMPLOYEE_DASHBOARD_ADD_EMPLOYEES: {
			return ({
				...state,
				viewList: [
					...state.viewList,
					...action.payload.employees,
				],
			});
		}

		case EMPLOYEE_DASHBOARD_SET_EMPLOYEES: {
			return {
				...state,
				...action.payload.employees,
			};
		}

		default: {
			return state;
		}
	}
}
