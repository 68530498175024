/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PayloadAction,
	createSlice,
} from "@reduxjs/toolkit";

import {
	type OvertimeRequests,
} from "models/overtime/types";

import {
	FILTER_OPTIONS_DEFAULT,
} from "../constants";
import {
	type FilterOptions,
} from "../types";

interface OvertimeDashboardPageState {
	displayedOvertimeRequests: OvertimeRequests;
	filterOptions: FilterOptions;
	searchString: string;
}

const initialState: OvertimeDashboardPageState = {
	displayedOvertimeRequests: [],
	filterOptions: FILTER_OPTIONS_DEFAULT,
	searchString: "",
};

const overtimeDashboardPageSlice = createSlice({
	name: "overtimeDashboardPage",
	initialState,
	reducers: {
		setFilterOptions: (
			state,
			action: PayloadAction<FilterOptions>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.filterOptions = action.payload;
		},
		setDisplayedOvertimeRequests: (
			state,
			action: PayloadAction<OvertimeRequests>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.displayedOvertimeRequests = action.payload;
		},
		setSearchString: (
			state,
			action: PayloadAction<string>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.searchString = action.payload;
		},
	},
});

const overtimeDashboardPageReducer = overtimeDashboardPageSlice.reducer;

const {
	setFilterOptions,
	setDisplayedOvertimeRequests,
	setSearchString,
} = overtimeDashboardPageSlice.actions;

export {
	initialState as overtimeDashboardPageInitialState,
	overtimeDashboardPageReducer,
	setFilterOptions,
	setDisplayedOvertimeRequests,
	setSearchString,
};
