/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Snackbar,
} from "@epam/loveship";
import {
	Modals,
} from "@epam/uui-components";
import {
	HistoryAdaptedRouter,
	UuiContext,
	useUuiServices,
} from "@epam/uui-core";
import {
	createBrowserHistory,
} from "history";
import {
	type SetupWorkerApi,
} from "msw";
import {
	type FC,
} from "react";
import {
	createRoot,
} from "react-dom/client";
import {
	Provider,
} from "react-redux";
import {
	Router,
} from "react-router-dom";

import {
	DiscardChangesModalWindow,
} from "pages/components/discard-changes-modal-window/discard-changes-modal-window";
import {
	Spinner,
} from "pages/components/spinner";
import {
	store,
} from "store";

import App from "./components/App";
import {
	svc,
} from "./constants/services";

// ESLint breaks application styles when fixing order.
/* eslint-disable import/order */
import "normalize.css";
import "./index.css";
import "@epam/uui-components/styles.css";
import "@epam/uui/styles.css";
import "@epam/loveship/styles.css";
/* eslint-enable import/order */

if (
	process.env.NODE_ENV === "development"
	&& process.env.REACT_APP_SHOULD_MOCK_API === "true"
) {
	interface MocksBrowserImport {
		mockApiWorker: SetupWorkerApi;
	}

	// We can't use import, because it's asynchronous.
	const {
		mockApiWorker,
	} = require("mocks/browser") as MocksBrowserImport;

	void mockApiWorker.start({
		onUnhandledRequest: "bypass",
	});
}

const history = createBrowserHistory({
	getUserConfirmation: async (message, resultHandler) => {
		try {
			await svc.uuiModals.show<undefined>((props) => {
				return (
					<DiscardChangesModalWindow
						{...props}
					/>
				);
			});

			// Route change confirmed.
			resultHandler(true);
		} catch {
			// Route change rejected.
			resultHandler(false);
		}
	},
});
const router = new HistoryAdaptedRouter(history);

const root = createRoot(
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	document.getElementById("root")!,
);

const ApplicationWithWrappers: FC = () => {
	const {
		services,
	} = useUuiServices({
		router,
	});

	Object.assign(svc, services);

	return (
		<Router history={history}>
			<Provider store={store}>
				<UuiContext.Provider
					value={services}
				>
					<Spinner/>
					<App/>
					<Snackbar/>
					<Modals/>
				</UuiContext.Provider>
			</Provider>
		</Router>
	);
};

root.render(
	<ApplicationWithWrappers/>,
);
