/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as clockIcon,
} from "@epam/assets/icons/common/action-schedule-outline-18.svg";
import {
	ReactComponent as closeIcon,
} from "@epam/assets/icons/common/navigation-close_bold-18.svg";
import {
	ReactComponent as checkIcon,
} from "@epam/assets/icons/common/notification-done_bold-18.svg";

import {
	ReactComponent as PresenceInProgress,
} from "icons/presence-status-in-progress-12.svg";

const JOURNAL_EDIT_MODE = {
	EDIT_WORKLOGS: "EDIT_WORKLOG",
	EDIT_OVERTIMES: "EDIT_OVERTIMES",
	MOVE_WORKLOGS: "MOVE_WORKLOGS",
};

const EDIT_STATUS = {
	NO_CHANGED: "NO_CHANGED",
	NEW: "NEW",
	CHANGED: "CHANGED",
	DELETED: "DELETED",
	PREDICTED: "PREDICTED",
};
const PRESENCE_STATUSES = {
	APPROVED: "APPROVED",
	SUBMITTED: "SUBMITTED",
	REJECTED: "REJECTED",
	PREFILLED: "PREFILLED",
	IN_PROGRESS: "IN_PROGRESS",
};

const PRESENCE_STATUS_PROPERTIES = {
	[PRESENCE_STATUSES.SUBMITTED]: {
		text: "Submitted",
		icon: clockIcon,
		class: "sky",
	},
	[PRESENCE_STATUSES.IN_PROGRESS]: {
		text: "In progress",
		icon: PresenceInProgress,
		class: "",
	},
	[PRESENCE_STATUSES.PREFILLED]: {
		text: "Prefilled",
		icon: "",
		class: "",
	},
	[PRESENCE_STATUSES.APPROVED]: {
		text: "Approved",
		icon: checkIcon,
		class: "grass",
	},
	[PRESENCE_STATUSES.REJECTED]: {
		text: "Rejected",
		icon: closeIcon,
		class: "fire",
	},
};

const MOVE_MODE = {
	NONE: "NONE",
	MOVE_WORKLOGS: "MOVE_WORKLOGS",
	MOVE_OVERTIMES: "MOVE_OVERTIMES",
};

const SPECIAL_PROJECT_TYPES = {
	NON_PROJECT_ACTIVITIES: "npa",
	VACATIONS: "vac",
};

/**
 * Validation rule types.

 * Please, keep it sorted alphabetically in ascending
 * order to make it easier to read.

 * @readonly
 * @enum {string}
 */
const VALIDATION_RULE_TYPES = {
	OVT_NORM_VALIDATION: "OVT_NORM_VALIDATION",
};

const TAB_TYPES = {
	ACTIVITIES: "Activities-autofill-tab",
	REPETITION_RATES: "Repetition-rate-autofill-tab",
	DAILY_GAP: "Daily-gap-autofill-tab",
	PRESENCES: "Presences-autofill-tab",
};

const TAB_TYPE_LABEL = {
	ACTIVITIES: "Activities",
	REPETITION_RATES: "Repeats",
	DAILY_GAP: "Daily gap",
	PRESENCES: "Presences",
};

const AUTOFILL_SETTING_TYPES = {
	ACTIVITY: "ACTIVITY",
	REPETITION_RATE: "REPETITION_RATE",
	DAILY_GAP: "DAILY_GAP",
	PRESENCE: "PRESENCE",
};

const TABS = [
	{
		type: TAB_TYPES.ACTIVITIES,
		label: TAB_TYPE_LABEL.ACTIVITIES,
	},
	{
		type: TAB_TYPES.REPETITION_RATES,
		label: TAB_TYPE_LABEL.REPETITION_RATES,
	},
	{
		type: TAB_TYPES.DAILY_GAP,
		label: TAB_TYPE_LABEL.DAILY_GAP,
	},
	{
		type: TAB_TYPES.PRESENCES,
		label: TAB_TYPE_LABEL.PRESENCES,
	},
];

const MAX_DAY_HOURS = 24;

const zeroDurations = [
	0,
];

const DAYS_SHORTED_NAME_LIST = [
	"Mon",
	"Tue",
	"Wed",
	"Thu",
	"Fri",
	"Sat",
	"Sun",
];

const MAX_REMINDER_NUMBERS_COUNT = 2;

const DAYS_OF_WEEK_ARR_FORMAT = 6;

const DAYS = [
	{
		key: "MON",
		value: "Monday",
		id: 1,
	},
	{
		key: "TUE",
		value: "Tuesday",
		id: 2,
	},
	{
		key: "WED",
		value: "Wednesday",
		id: 3,
	},
	{
		key: "THU",
		value: "Thursday",
		id: 4,
	},
	{
		key: "FRI",
		value: "Friday",
		id: 5,
	},
	{
		key: "SAT",
		value: "Saturday",
		id: 6,
	},
	{
		key: "SUN",
		value: "Sunday",
		id: 7,
	},
];

const WORK_DAYS = DAYS.filter((o) => {
	return o.id < DAYS_OF_WEEK_ARR_FORMAT;
});

const PRESENCE_ACTIVITIES = {
	ACTIVITY: "ACTIVITY",
	REST: "REST",
	ON_DUTY: "ON_DUTY",
};

const PRESENCE_TYPE = {
	SINGLE: "SINGLE",
	MULTI: "MULTI",
};

const VACATIONS_TYPES_ORDER = {
	"vac-reg": 0,
	"vac-ill": 1,
	"vac-exv": 2,
	"vac-pov": 3,
	"vac-ovt": 4,
};

const PRESENCE_ERROR_MESSAGES = {
	INCORRECT_START_TIME: "Please enter start time for the presence.",
	TIME_OVERLAPS: "Please enter another start/end time to avoid overlaps.",
	INCORRECT_START_END_TIME: "Please enter start/end time for the presence.",
	OUT_OF_RANGE: "Please enter another start/end time to stay within your working day.",
};

const ONLY_START_TIME_REQUIRED_PRESENCE_VALIDATION_ROLE = "ONLY_START_TIME_REQUIRED_PRESENCE_VALIDATION_ROLE";

// In pixels.
const MAX_WIDTH_FOR_CONNECT_BANNER = 561;

export {
	ONLY_START_TIME_REQUIRED_PRESENCE_VALIDATION_ROLE,
	PRESENCE_ERROR_MESSAGES,
	VACATIONS_TYPES_ORDER,
	PRESENCE_TYPE,
	PRESENCE_ACTIVITIES,
	WORK_DAYS,
	DAYS,
	MAX_REMINDER_NUMBERS_COUNT,
	DAYS_SHORTED_NAME_LIST,
	zeroDurations,
	MAX_DAY_HOURS,
	TABS,
	AUTOFILL_SETTING_TYPES,
	TAB_TYPES,
	VALIDATION_RULE_TYPES,
	SPECIAL_PROJECT_TYPES,
	MOVE_MODE,
	PRESENCE_STATUS_PROPERTIES,
	PRESENCE_STATUSES,
	EDIT_STATUS,
	JOURNAL_EDIT_MODE,
	MAX_WIDTH_FOR_CONNECT_BANNER,
};
