/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FilterLocalStorageKey,
} from "components/common-components/filter-wrapper/filter-panel/filters-panel";
import {
	REQUEST_STATUS,
} from "constants/api";
import {
	OVERTIME_STATUS_NAME,
	OvertimeStatus,
} from "models/overtime/constants";

import {
	type FilterOptions,
	type FilterValues,
	type TableState,
} from "./types";

const ROLE_NO_POSITION = "No position";

const STATUS_FILTER_OPTIONS: FilterOptions["statuses"] = [
	{
		id: OvertimeStatus.APPROVED,
		value: OVERTIME_STATUS_NAME[OvertimeStatus.APPROVED],
	},
	{
		id: OvertimeStatus.CANCELLED,
		value: OVERTIME_STATUS_NAME[OvertimeStatus.CANCELLED],
	},
	{
		id: OvertimeStatus.REJECTED,
		value: OVERTIME_STATUS_NAME[OvertimeStatus.REJECTED],
	},
	{
		id: OvertimeStatus.SUBMITTED,
		value: OVERTIME_STATUS_NAME[OvertimeStatus.SUBMITTED],
	},
];

const FILTER_OPTIONS_DEFAULT: FilterOptions = {
	projects: [],
	customers: [],
	locations: [],
	roles: [],
	types: [],
	statuses: STATUS_FILTER_OPTIONS,
};

const FILTER_VALUES_DEFAULT: FilterValues = {
	projects: [],
	customers: [],
	locations: [],
	roles: [],
	date: null,
	types: [],
	statuses: [],
	shouldIncludeOnlyDirectMembers: false,
	shouldIncludeOnlyRequestsWithAttachments: false,
};

const FILTERS_LOCAL_STORAGE_KEY: FilterLocalStorageKey = "overtime-dashboard-filters";

const SHOULD_INCLUDE_ONLY_MY_OVERTIME_LOCAL_STORAGE_KEY: FilterLocalStorageKey = (
	"overtime-dashboard-should-include-only-my-overtime"
);

const INITIAL_TABLE_STATE: Readonly<TableState> = {
	scrollTo: {
		index: 0,
	},
	checked: [],
	status: REQUEST_STATUS.IDLE,
	errorResponseStatus: undefined,
};

const BULK_ACTIONS_PANEL_HEIGHT = 54;

export {
	ROLE_NO_POSITION,
	FILTER_OPTIONS_DEFAULT,
	FILTER_VALUES_DEFAULT,
	FILTERS_LOCAL_STORAGE_KEY,
	SHOULD_INCLUDE_ONLY_MY_OVERTIME_LOCAL_STORAGE_KEY,
	INITIAL_TABLE_STATE,
	BULK_ACTIONS_PANEL_HEIGHT,
};
