/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PayloadAction,
	createSlice,
} from "@reduxjs/toolkit";

import {
	type OvertimeRequest,
} from "models/overtime/types";

interface OvertimeDetailsModalWindowState {
	viewedOvertime: OvertimeRequest | null;
	accessDenied: boolean;
}

const initialState: OvertimeDetailsModalWindowState = {
	viewedOvertime: null,
	accessDenied: false,
};

const overtimeDetailsModalWindowSlice = createSlice({
	name: "overtimeDetailsModalWindow",
	initialState,
	reducers: {
		updateOvertimeDetailsModalWindow: (
			state,
			action: PayloadAction<Partial<OvertimeDetailsModalWindowState>>,
		) => {
			return {
				...state,
				...action.payload,
			};
		},
	},
});

const overtimeDetailsModalWindowReducer = overtimeDetailsModalWindowSlice.reducer;

const {
	updateOvertimeDetailsModalWindow,
} = overtimeDetailsModalWindowSlice.actions;

export {
	overtimeDetailsModalWindowReducer,
	updateOvertimeDetailsModalWindow,
};
