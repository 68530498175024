/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	GET_TMSLOG,
} from "./actions";

const defaultState = {
	worklogs: [],
	projectName: "",
	accountTypes: [],
};

const reducer = (
	// It is a correct approach for Redux reducers.
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state = defaultState,
	action,
) => {
	if (action) {
		switch (action.type) {
			case GET_TMSLOG: {
				return {
					...state,
					worklogs: action.worklogs,
					projectName: action.projectName,
					accountTypes: action.accountTypes,
				};
			}

			default: {
				return state;
			}
		}
	} else {
		return state;
	}
};

export default reducer;
