/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PayloadAction,
	createSlice,
} from "@reduxjs/toolkit";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import uniq from "lodash/uniq";
import without from "lodash/without";

import {
	type DateString,
} from "models/dates-and-time/types";
import {
	type EmployeeId,
	type EmployeeIds,
} from "models/employees/types";
import {
	type PROJECT_TIP,
} from "models/tips/constants";
import {
	type CallToActionTips,
	type ProjectTipTypes,
	type ProjectTips,
} from "models/tips/types";

interface ProjectDetailedPageState {
	tipsMap: Record<EmployeeId, Record<DateString, ProjectTips>>;
	selectedTipTypes: ProjectTipTypes;
	// Employees' IDs for current table's pagination page (including applied filters).
	displayedEmployeesIds: EmployeeIds;
}

const initialState: ProjectDetailedPageState = {
	tipsMap: {},
	selectedTipTypes: [],
	displayedEmployeesIds: [],
};

const projectDetailedPageSlice = createSlice({
	name: "projectDetailedPage",
	initialState,
	reducers: {
		setTipsMap: (
			state,
			action: PayloadAction<CallToActionTips<PROJECT_TIP>>,
		) => {
			const tipsByEmployee = groupBy(
				action.payload,
				(employeeTip) => {
					return employeeTip.employeeId;
				},
			);

			// eslint-disable-next-line no-param-reassign
			state.tipsMap = mapValues(
				tipsByEmployee,
				(employeeTips) => {
					return groupBy(
						employeeTips,
						(employeeTip) => {
							return employeeTip.timesheetDate;
						},
					);
				},
			);
		},
		selectTipType: (
			state,
			action: PayloadAction<PROJECT_TIP>,
		) => {
			const nextSelectedTipTypes: ProjectTipTypes = [
				...state.selectedTipTypes,
				action.payload,
			];

			// eslint-disable-next-line no-param-reassign
			state.selectedTipTypes = uniq(nextSelectedTipTypes);
		},
		unselectTipType: (
			state,
			action: PayloadAction<PROJECT_TIP>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.selectedTipTypes = without(
				state.selectedTipTypes,
				action.payload,
			);
		},
		unselectAllTipTypes: (state) => {
			// eslint-disable-next-line no-param-reassign
			state.selectedTipTypes = [];
		},
		setDisplayedEmployeesIds: (
			state,
			action: PayloadAction<EmployeeIds>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.displayedEmployeesIds = action.payload;
		},
		resetState: () => {
			return initialState;
		},
	},
});

const projectDetailedPageReducer = projectDetailedPageSlice.reducer;

const {
	setTipsMap,
	selectTipType,
	unselectTipType,
	unselectAllTipTypes,
	setDisplayedEmployeesIds,
	resetState,
} = projectDetailedPageSlice.actions;

export {
	projectDetailedPageReducer,
	setTipsMap as setProjectTips,
	selectTipType as selectProjectTip,
	unselectTipType as unselectProjectTip,
	unselectAllTipTypes as unselectAllProjectTips,
	setDisplayedEmployeesIds,
	resetState as resetProjectDetailedPageState,
};
