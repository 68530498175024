/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PayloadAction, createSlice,
} from "@reduxjs/toolkit";

interface ConnectBannerState {
	isOpened: boolean;
}

const initialState: ConnectBannerState = {
	isOpened: true,
};

const connectBannerSlice = createSlice({
	name: "connectBanner",
	initialState,
	reducers: {
		setIsOpened: (
			state,
			action: PayloadAction<boolean>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.isOpened = action.payload;
		},
	},
});

const connectBannerReducer = connectBannerSlice.reducer;
const {
	setIsOpened,
} = connectBannerSlice.actions;

export {
	connectBannerReducer,
	setIsOpened as setIsConnectBannerOpened,
};
