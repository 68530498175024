/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	RESET_PROJECTS_DASHBOARD_DATA,
	UPDATE_PROJECTS_DASHBOARD_DATA,
} from "./actions";

const initState = {
	dates: [],
	redLock: {},
	filter: {
		projects: [],
		customers: [],
	},
	projects: {},
	showedProjects: [],
	markupStatuses: {},
	filteredProjects: [],
	showDetailsProjectId: null,
	filterMarkupStatusValues: {},
};

export default function reducer(
	// It is a correct approach for Redux reducers.
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state = initState,
	action,
) {
	switch (action.type) {
		case UPDATE_PROJECTS_DASHBOARD_DATA: {
			return {
				...state,
				...action.payload,
				filterMarkupStatusValues: {
					...state.filterMarkupStatusValues,
					...action.payload.filterMarkupStatusValues,
				},
				markupStatuses: {
					...state.markupStatuses,
					...action.payload.markupStatuses,
				},
			};
		}

		case RESET_PROJECTS_DASHBOARD_DATA: {
			return initState;
		}

		default: {
			return state;
		}
	}
}
