/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";

import {
	type EmployeeTipTypesByDate,
	type EmployeesTipsInfo,
	type EmployeesTipsInfoByDate,
} from "models/tips/types";

const getTipTypesByDate = (
	employeeTipsInfo: EmployeesTipsInfo,
): EmployeeTipTypesByDate => {
	const employeeTipsInfoByDate: EmployeesTipsInfoByDate = groupBy(
		employeeTipsInfo,
		(employeeTipInfo) => {
			return employeeTipInfo.timesheetDate;
		},
	);

	const employeeTipTypesByDate: EmployeeTipTypesByDate = mapValues(
		employeeTipsInfoByDate,
		(employeeTipsInfoForDate) => {
			return employeeTipsInfoForDate.map(
				({
					type,
				}) => {
					return type;
				},
			);
		},
	);

	return employeeTipTypesByDate;
};

export {
	getTipTypesByDate,
};
