/*
 * Copyright © 2021 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	SET_PACKAGE_EDIT_DATA,
} from "./actions";

const initState = {
	projectName: "",
	positions: {},
	roles: {},
	breakdowns: {},
	breakdownGroups: {},
	structure: {},
	rootStreamIds: [],
	existingPackages: [],
	minLimitDate: null,
	isClosedOrOnHold: false,

	currentPositions: {},
	acceptablePositions: [],
};

export default function reducer(
	// It is a correct approach for Redux reducers.
	// eslint-disable-next-line @typescript-eslint/default-param-last
	state = initState,
	action,
) {
	switch (action.type) {
		case SET_PACKAGE_EDIT_DATA: {
			return {
				...state,
				...action.payload,
			};
		}

		default: {
			return state;
		}
	}
}
