/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PayloadAction,
	createSlice,
} from "@reduxjs/toolkit";
import groupBy from "lodash/groupBy";
import isUndefined from "lodash/isUndefined";
import mapValues from "lodash/mapValues";
import uniq from "lodash/uniq";
import without from "lodash/without";

import {
	type DateString,
} from "models/dates-and-time/types";
import {
	type EmployeeId,
	type EmployeeIds,
} from "models/employees/types";
import {
	type EmployeesPresencesInfo,
	type Presences,
	type PresencesByEmployeeId,
	type PresencesByEmployeeIdByDate,
} from "models/presences/types";
import {
	type EMPLOYEE_TIP_TYPES,
	type EmployeeTipType,
} from "models/tips/constants";
import {
	type CallToActionTips,
	type EmployeeTipTypes,
} from "models/tips/types";

import {
	getTipTypesByDate,
} from "../utils/get-tip-types-by-date";

type EmployeeTips = CallToActionTips<EmployeeTipType>;

type TipsByDate = Record<DateString, typeof EMPLOYEE_TIP_TYPES>;

interface EmployeesDashboardPageState {
	areAllEmployeesUnselected: boolean;
	// Tip types grouped by employee id, then by date.
	tipTypesMap: Record<EmployeeId, TipsByDate>;
	selectedEmployeeTipTypes: EmployeeTipTypes;
	// Employees' IDs for current table's pagination page (including applied filters).
	displayedEmployeesIds: EmployeeIds;
	totalEmployeesCount: number;
	presencesByEmployeeId: PresencesByEmployeeId;
	presencesByEmployeeIdByDate: PresencesByEmployeeIdByDate;
}

const getInitialState = (
	override?: Partial<EmployeesDashboardPageState>,
): EmployeesDashboardPageState => {
	return {
		areAllEmployeesUnselected: true,
		tipTypesMap: {},
		selectedEmployeeTipTypes: [],
		displayedEmployeesIds: [],
		totalEmployeesCount: 0,
		presencesByEmployeeId: {},
		presencesByEmployeeIdByDate: {},
		...override,
	};
};

const initialState = getInitialState();

const employeesDashboardPageSlice = createSlice({
	name: "employeesDashboardPage",
	initialState,
	reducers: {
		setTipTypesMap: (
			state,
			action: PayloadAction<EmployeeTips>,
		) => {
			const tipsByEmployee = groupBy(
				action.payload,
				(employeeTip) => {
					return employeeTip.employeeId;
				},
			);

			// eslint-disable-next-line no-param-reassign
			state.tipTypesMap = mapValues(
				tipsByEmployee,
				(employeeTips) => {
					return getTipTypesByDate(employeeTips);
				},
			);
		},
		selectEmployeeTip: (
			state,
			action: PayloadAction<EmployeeTipType>,
		) => {
			const nextSelectedEmployeeTipTypes: EmployeeTipTypes = [
				...state.selectedEmployeeTipTypes,
				action.payload,
			];

			// eslint-disable-next-line no-param-reassign
			state.selectedEmployeeTipTypes = uniq(nextSelectedEmployeeTipTypes);
		},
		unselectEmployeeTip: (
			state,
			action: PayloadAction<EmployeeTipType>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.selectedEmployeeTipTypes = without(
				state.selectedEmployeeTipTypes,
				action.payload,
			);
		},
		unselectAllEmployeeTips: (state) => {
			// eslint-disable-next-line no-param-reassign
			state.selectedEmployeeTipTypes = [];
		},
		setDisplayedEmployeesIds: (
			state,
			action: PayloadAction<EmployeeIds>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.displayedEmployeesIds = action.payload;
		},
		setTotalEmployeesCount: (
			state,
			action: PayloadAction<number>,
		) => {
			// eslint-disable-next-line no-param-reassign
			state.totalEmployeesCount = action.payload;
		},
		setEmployeesPresences: (
			state,
			action: PayloadAction<EmployeesPresencesInfo>,
		) => {
			const presencesDataByEmployeeId = groupBy(
				action.payload,
				({
					employeeId,
				}) => {
					return employeeId;
				},
			);

			const presencesByEmployeeId = mapValues(
				presencesDataByEmployeeId,
				(presencesDataForEmployee) => {
					/*
						It is expected, that only one presence meta-data is assigned to
						an employee.
					*/
					const presencesInfoForEmployee = presencesDataForEmployee.at(0);

					if (isUndefined(presencesInfoForEmployee)) {
						return [] as Presences;
					}

					return presencesInfoForEmployee.presenceInfo.reduce<
						Presences
					>(
						(currentEmployeePresences, presencePeriod) => {
							return currentEmployeePresences.concat(presencePeriod.presences);
						},
						[],
					);
				},
			);

			// eslint-disable-next-line no-param-reassign
			state.presencesByEmployeeId = presencesByEmployeeId;

			const presencesByEmployeeIdByDate = mapValues(
				presencesByEmployeeId,
				(employeePresences) => {
					const employeePresencesByDate = groupBy(
						employeePresences,
						({
							timesheetDate,
						}) => {
							return timesheetDate;
						},
					);

					return mapValues(
						employeePresencesByDate,
						(presencesForDate) => {
							// It is expected, that only one presence can be assigned to a date.
							return presencesForDate.at(0);
						},
					);
				},
			);

			// eslint-disable-next-line no-param-reassign
			state.presencesByEmployeeIdByDate = presencesByEmployeeIdByDate;
		},
		resetState: () => {
			return initialState;
		},
	},
});

const employeesDashboardPageReducer = employeesDashboardPageSlice.reducer;

const {
	selectEmployeeTip,
	unselectEmployeeTip,
	unselectAllEmployeeTips,
	setDisplayedEmployeesIds,
	setTotalEmployeesCount,
	setEmployeesPresences,
	setTipTypesMap,
	resetState,
} = employeesDashboardPageSlice.actions;

export {
	employeesDashboardPageReducer,
	getInitialState as getInitialEmployeesDashboardPageState,
	selectEmployeeTip,
	unselectEmployeeTip,
	unselectAllEmployeeTips,
	setDisplayedEmployeesIds,
	setTotalEmployeesCount,
	setEmployeesPresences,
	setTipTypesMap as setEmployeeTipTypesMap,
	resetState as resetEmployeesDashboardPageState,
};
