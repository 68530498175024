/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const SET_JOURNAL_DATA = "SET_JOURNAL_DATA";
const GET_HISTORY_DATA = "GET_HISTORY_DATA";
const INIT_JOURNAL_DATA = "INIT_JOURNAL_DATA";

export {
	SET_JOURNAL_DATA,
	GET_HISTORY_DATA,
	INIT_JOURNAL_DATA,
};
