/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
const PERIODS = {
	DAY: "day",
	WEEK: "week",
	TWO_WEEKS: "2 weeks",
	MONTH: "month",
};

const NAV_PERIODS = {
	MONTH: PERIODS.MONTH,
};

const CUSTOM_PERIOD = "custom";

const SAVE_LOCK_ERROR = {
	BILLING: [
		"pmc.billing.lock",
		"conflict.lock.billing",
	],
	REPORTING: [
		"pmc.reporting.lock",
		"conflict.lock.reporting",
	],
};

export {
	PERIODS,
	NAV_PERIODS,
	CUSTOM_PERIOD,
	SAVE_LOCK_ERROR,
};
