/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ApmRoute,
} from "@elastic/apm-rum-react";
import {
	endOfMonth,
	parseISO,
	startOfMonth,
} from "date-fns";
import isEmpty from "lodash/isEmpty";
import {
	Component,
	Suspense,
	lazy,
	useEffect,
} from "react";
import {
	ErrorBoundary,
} from "react-error-boundary";
import {
	connect,
	useSelector,
} from "react-redux";
import {
	Redirect,
	Switch,
	matchPath,
	withRouter,
} from "react-router-dom";

import {
	NAV_PERIODS,
} from "environment/journal_environment";
import {
	calcFromAndTo,
} from "models/dates-and-time/utils/common";
import {
	toDateString,
} from "models/dates-and-time/utils/to-date-string";
import {
	Header,
} from "pages/components/header/index";
import {
	PagePeriodPresetLocalStorageKey,
} from "pages/components/page-period-navigator/constants";
import {
	getPagePeriodNavigatorPreset,
} from "pages/components/page-period-navigator/utils/get-page-period-navigator-preset";
import {
	getPeriodDatesByPreset,
} from "pages/components/page-period-navigator/utils/get-period-dates-by-preset";
import {
	SpinnerBody,
} from "pages/components/spinner";
import {
	hideSpinner,
} from "pages/components/spinner/store/reducer";
import {
	EMPLOYEES_BASE_URL,
	OVERTIME_BASE_URL,
	PROJECTS_BASE_URL,
	TIME_JOURNAL_BASE_URL,
} from "pages/constants/base-routes";
import {
	EmployeesDashboardView,
} from "pages/constants/employees-page";
import {
	CLONE_PACKAGE_ROUTE_PATH,
	EDIT_PACKAGE_ROUTE_PATH,
	EMPLOYEES_DASHBOARD_NEW_VIEW_ROUTE_PATH,
	EMPLOYEES_DASHBOARD_OLD_VIEW_ROUTE_PATH,
	EMPLOYEE_ID_PATH_PATTERN,
	FROM_DATE_PATH_PATTERN,
	ISSUE_MAPPING_ROUTE_PATH,
	NEW_PACKAGE_ROUTE_PATH,
	OVERTIME_DASHBOARD_ROUTE_PATH,
	OVERTIME_DETAILS_ROUTE_PATH,
	PACKAGES_DASHBOARD_ROUTE_PATH,
	PROJECTS_DASHBOARD_ROUTE_PATH,
	PROJECT_DETAILS_NEW_VIEW_ROUTE_PATH,
	PROJECT_DETAILS_OLD_VIEWS_ROUTE_PATH,
	PROJECT_ID_PATH_PATTERN,
	TIME_JOURNAL_ROUTE_PATH,
	TMS_LOG_ROUTE_PATH,
	TO_DATE_PATH_PATTERN,
} from "pages/constants/routes";
import {
	generateEmployeesPageUrl,
} from "pages/utils/generate-employees-page-url";
import {
	generateOvertimeDashboardPageUrl,
} from "pages/utils/generate-overtime-dashboard-page-url";
import {
	generateOvertimeRequestDetailsPageUrl,
} from "pages/utils/generate-overtime-request-details-page-url";
import {
	generatePackagesPageUrl,
} from "pages/utils/generate-packages-page-url";
import {
	generateProjectPageUrl,
} from "pages/utils/generate-project-page-url";
import {
	generateProjectsPageUrl,
} from "pages/utils/generate-projects-page-url";
import {
	generateTimeJournalPageUrl,
} from "pages/utils/generate-time-journal-page-url";
import {
	getEmployeesDashboardViewFromLocalStorage,
} from "pages/utils/get-employees-dashboard-view-from-local-storage";
import {
	getProjectDetailsViewFromLocalStorage,
} from "pages/utils/get-project-details-view-from-local-storage";
import {
	dispatch,
} from "store";
import {
	getUserId,
} from "store/slices/application-info/selectors";
import {
	getApplicationInfo,
} from "store/slices/application-info/utils/get-application-info";
import {
	startApmAgent,
} from "utils/apm";

import {
	JOURNAL_EDIT_MODE,
} from "./journal/consts";

import "./App.css";

const EmployeesDashboardPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "employees-dashboard-page" */
			"components/employees-dashboard/EmployeesDashboard"
		);
	},
);
const JournalPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "time-journal-page" */
			"components/journal/Journal"
		);
	},
);
const IssueMappingPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "issue-mapping-page" */
			"components/packages/issue-mapping/IssueMapping"
		);
	},
);
const PackageEditPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "package-details-page" */
			"components/packages/package-edit/PackageEdit"
		);
	},
);
const PackageListPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "packages-dashboard-page" */
			"components/packages/packages-list/PackagesList"
		);
	},
);
const ProjectsBillingDashboardPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "projects-dashboard-page" */
			"components/projects-billing/projects-dashboard/ProjectsDashboard"
		);
	},
);
const ProjectBillingDetailsPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "project-details-page" */
			"components/projects-billing/project-details/ProjectDetails"
		);
	},
);
const TMSLogPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "time-ms-log-page" */
			"components/tms-log/TMSLog"
		);
	},
);
const ProjectPositionBreakdownPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "project-details-position-breakdown-view-page" */
			"pages/project-position-breakdown"
		);
	},
);
const NewEmployeesDashboardPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "employees-dashboard-employees-details-view-page" */
			"pages/employees-dashboard/employees-dashboard"
		);
	},
);
const OvertimeDashboardPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "overtime-dashboard-page" */
			"pages/overtime-dashboard/overtime-dashboard"
		);
	},
);
const OvertimeDetailsPage = lazy(
	async () => {
		return await import(
			/* webpackChunkName: "overtime-details-page" */
			"pages/overtime-details/overtime-details"
		);
	},
);

const apmAgent = startApmAgent();

/** @type {import("react").FC} */
const PageNotFoundBase = ({
	hideSpinner,
}) => {
	useEffect(
		() => {
			hideSpinner();
		},
		[
			hideSpinner,
		],
	);

	return (
		<div>
			please check the url
		</div>
	);
};

const PageNotFound = connect(
	undefined,
	{
		hideSpinner,
	},
)(PageNotFoundBase);

/**
 * @typedef {string} ChunkFailedErrorLocalStorageKey
 */

/** @type {ChunkFailedErrorLocalStorageKey} */
const CHUNK_FAILED_ERROR_LOCAL_STORAGE_KEY = "chunk_failed";

/**
 * @typedef {Object} ChunkFailedErrorLocalStorageValue
 * @property {"true" | "false"} value
 * @property {number} expiry
 */

/**
 * @param {ChunkFailedErrorLocalStorageKey} key
 * @returns {ChunkFailedErrorLocalStorageValue["value"] | null}
 */
const getWithExpiry = (key) => {
	const localStorageValue = window.localStorage.getItem(key);

	if (!localStorageValue) {
		return null;
	}

	/** @type {ChunkFailedErrorLocalStorageValue} */
	const localStorageValueParsed = JSON.parse(localStorageValue);
	const isExpired = new Date().getTime() > localStorageValueParsed.expiry;

	if (isExpired) {
		localStorage.removeItem(key);

		return null;
	}

	return localStorageValueParsed.value;
};

/**
 * @typedef {Object} SetWithExpiryParams
 * @property {ChunkFailedErrorLocalStorageKey} key
 * @property {ChunkFailedErrorLocalStorageValue["value"]} value
 * @property {number} ttl
 */

/**
 * @param {SetWithExpiryParams} params
 * @returns {void}
 */
const setWithExpiry = ({
	key,
	value,
	ttl,
}) => {
	/** @type {ChunkFailedErrorLocalStorageValue} */
	const localStorageValue = {
		value,
		expiry: new Date().getTime() + ttl,
	};

	localStorage.setItem(
		key,
		JSON.stringify(localStorageValue),
	);
};

/**
 * Inspired by: https://mitchgavan.com/code-splitting-react-safely/
 * @type {import("react").FC<import("react-error-boundary").FallbackProps>}
 */
const ErrorFallback = ({
	error,
}) => {
	useEffect(
		() => {
			const chunkFailedMessage = /Loading chunk [\d]+ failed/;

			if (
				error.message
				&& chunkFailedMessage.test(error.message)
			) {
				if (!getWithExpiry(CHUNK_FAILED_ERROR_LOCAL_STORAGE_KEY)) {
					setWithExpiry({
						key: CHUNK_FAILED_ERROR_LOCAL_STORAGE_KEY,
						value: "true",
						ttl: 10_000,
					});

					window.location.reload();
				}
			}
		},
		[
			error,
		],
	);

	return null;
};

const SuspenseFallback = () => {
	const isSpinnerVisible = useSelector((state) => {
		return state.spinner.isSpinnerVisible;
	});

	// Suspense fallback should be visible only in case when global spinner is hidden and vice versa.
	// In order to avoid intersection of the general spinner and Suspense fallback spinner.
	if (!isSpinnerVisible) {
		return <SpinnerBody/>;
	}

	return null;
};

/**
 * @typedef {Object} AppReduxProps
 * @property {import("models/user-info/types").UserId} userId
 */

/**
 * @typedef {import("react-router-dom").RouteComponentProps & AppReduxProps} AppProps
 */

/**
 * @extends {Component<AppProps, Object>}
 */
class App extends Component {
	componentDidMount() {
		getApplicationInfo(dispatch);
	}

	/**
	 * @param {AppReduxProps} previousProps
	 * @returns {void}
	 */
	componentDidUpdate(previousProps) {
		const {
			userId,
		} = this.props;

		if (
			isEmpty(previousProps.userId)
			&& !isEmpty(userId)
		) {
			apmAgent.setUserContext({
				id: userId,
			});
		}
	}

	render() {
		const {
			location: {
				pathname,
			},
			userId,
		} = this.props;

		const packagePeriodStr = localStorage.getItem("epmtime.package_period");

		const packagesPeriod = packagePeriodStr
			? JSON.parse(packagePeriodStr)
			: calcFromAndTo({
				period: NAV_PERIODS.MONTH,
				date: new Date(),
			});

		const isJournalPageOpen = Boolean(
			matchPath(
				pathname,
				TIME_JOURNAL_ROUTE_PATH,
			),
		);

		if (isEmpty(userId)) {
			return null;
		}

		return (
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<div
					id="app"
					className="app"
				>
					<div id="blocker-portal"/>
					<Header/>
					<main
						style={
							isJournalPageOpen
								? {
									position: "relative",
								}
								: {}
						}
					>
						<Suspense fallback={<SuspenseFallback/>}>
							<Switch>
								<ApmRoute
									exact={true}
									path={[
										"/",
										TIME_JOURNAL_BASE_URL,
									]}
									render={() => {
										const preset = getPagePeriodNavigatorPreset(
											PagePeriodPresetLocalStorageKey.TIME_JOURNAL,
										);

										const {
											periodStartDate,
											periodEndDate,
										} = getPeriodDatesByPreset({
											preset,
										});

										return (
											<Redirect
												to={
													generateTimeJournalPageUrl({
														employeeId: userId,
														periodStartDate,
														periodEndDate,
													})
												}
											/>
										);
									}}
								/>
								<ApmRoute
									exact={true}
									path={
										generateTimeJournalPageUrl({
											employeeId: EMPLOYEE_ID_PATH_PATTERN,
										})
									}
									render={({
										match: {
											params: {
												userId,
											},
										},
									}) => {
										const preset = getPagePeriodNavigatorPreset(
											PagePeriodPresetLocalStorageKey.TIME_JOURNAL,
										);

										const {
											periodStartDate,
											periodEndDate,
										} = getPeriodDatesByPreset({
											preset,
										});

										return (
											<Redirect
												to={
													generateTimeJournalPageUrl({
														employeeId: userId,
														periodStartDate,
														periodEndDate,
													})
												}
											/>
										);
									}}
								/>
								<ApmRoute
									path={TIME_JOURNAL_ROUTE_PATH}
									component={(props) => {
										return (
											<JournalPage
												{...props}
												editMode={JOURNAL_EDIT_MODE.EDIT_WORKLOGS}
											/>
										);
									}}
								/>

								<ApmRoute
									exact={true}
									path={OVERTIME_DASHBOARD_ROUTE_PATH}
									component={OvertimeDashboardPage}
								/>

								<ApmRoute
									exact={true}
									path={OVERTIME_DETAILS_ROUTE_PATH}
									component={OvertimeDetailsPage}
								/>

								<ApmRoute
									exact={true}
									path={OVERTIME_BASE_URL}
									render={() => {
										const preset = getPagePeriodNavigatorPreset(
											PagePeriodPresetLocalStorageKey.OVERTIME_DASHBOARD,
										);

										const {
											periodStartDate,
											periodEndDate,
										} = getPeriodDatesByPreset({
											preset,
										});

										return (
											<Redirect
												to={
													generateOvertimeDashboardPageUrl({
														periodStartDate,
														periodEndDate,
													})
												}
											/>
										);
									}}
								/>

								{/* TODO: Remove 3 components below when the time has come. */}
								<ApmRoute
									exact={true}
									path="/overtimes/from=:fromDate&to=:toDate"
									render={({
										match: {
											params: {
												fromDate,
												toDate,
											},
										},
									}) => {
										return (
											<Redirect
												to={
													generateOvertimeDashboardPageUrl({
														periodStartDate: fromDate,
														periodEndDate: toDate,
													})
												}
											/>
										);
									}}
								/>

								<ApmRoute
									exact={true}
									path="/overtimes/id=:overtimeRequestId"
									render={({
										match: {
											params: {
												overtimeRequestId,
											},
										},
									}) => {
										return (
											<Redirect
												to={
													generateOvertimeRequestDetailsPageUrl({
														overtimeRequestId,
													})
												}
											/>
										);
									}}
								/>

								<ApmRoute
									exact={true}
									path="/(overtimes)?"
									render={() => {
										const preset = getPagePeriodNavigatorPreset(
											PagePeriodPresetLocalStorageKey.OVERTIME_DASHBOARD,
										);

										const {
											periodStartDate,
											periodEndDate,
										} = getPeriodDatesByPreset({
											preset,
										});

										return (
											<Redirect
												to={
													generateOvertimeDashboardPageUrl({
														periodStartDate,
														periodEndDate,
													})
												}
											/>
										);
									}}
								/>

								<ApmRoute
									exact={true}
									sensitive={true}
									path={PROJECT_DETAILS_NEW_VIEW_ROUTE_PATH}
									component={ProjectPositionBreakdownPage}
								/>
								<ApmRoute
									exact={true}
									sensitive={true}
									path={PROJECTS_DASHBOARD_ROUTE_PATH}
									component={ProjectsBillingDashboardPage}
								/>
								<ApmRoute
									exact={true}
									sensitive={true}
									path={PROJECT_DETAILS_OLD_VIEWS_ROUTE_PATH}
									component={ProjectBillingDetailsPage}
								/>
								<ApmRoute
									exact={true}
									path={
										generateProjectPageUrl({
											projectId: PROJECT_ID_PATH_PATTERN,
											periodStartDate: FROM_DATE_PATH_PATTERN,
											periodEndDate: TO_DATE_PATH_PATTERN,
										})
									}
									render={({
										match: {
											params: {
												projectId,
												fromDate,
												toDate,
											},
										},
									}) => {
										const projectDetailsView = getProjectDetailsViewFromLocalStorage();

										return (
											<Redirect
												to={
													generateProjectPageUrl({
														projectId,
														periodStartDate: fromDate,
														periodEndDate: toDate,
														viewMode: projectDetailsView,
													})
												}
											/>
										);
									}}
								/>
								<ApmRoute
									exact={true}
									path={
										generateProjectPageUrl({
											projectId: PROJECT_ID_PATH_PATTERN,
										})
									}
									render={({
										match: {
											params: {
												projectId,
											},
										},
									}) => {
										const preset = getPagePeriodNavigatorPreset(
											PagePeriodPresetLocalStorageKey.PROJECT_DETAILS,
										);

										const {
											periodStartDate,
											periodEndDate,
										} = getPeriodDatesByPreset({
											preset,
										});

										const projectDetailsView = getProjectDetailsViewFromLocalStorage();

										return (
											<Redirect
												to={
													generateProjectPageUrl({
														projectId,
														periodStartDate,
														periodEndDate,
														viewMode: projectDetailsView,
													})
												}
											/>
										);
									}}
								/>
								<ApmRoute
									exact={true}
									path={PROJECTS_BASE_URL}
									render={() => {
										const preset = getPagePeriodNavigatorPreset(
											PagePeriodPresetLocalStorageKey.PROJECTS_DASHBOARD,
										);

										const {
											periodStartDate,
											periodEndDate,
										} = getPeriodDatesByPreset({
											preset,
										});

										return (
											<Redirect
												to={
													generateProjectsPageUrl({
														periodStartDate,
														periodEndDate,
													})
												}
											/>
										);
									}}
								/>

								<ApmRoute
									exact={true}
									path={EMPLOYEES_DASHBOARD_NEW_VIEW_ROUTE_PATH}
									component={NewEmployeesDashboardPage}
								/>
								<ApmRoute
									exact={true}
									path={EMPLOYEES_DASHBOARD_OLD_VIEW_ROUTE_PATH}
									component={EmployeesDashboardPage}
								/>
								<ApmRoute
									exact={true}
									path={EMPLOYEES_BASE_URL}
									render={() => {
										const preset = getPagePeriodNavigatorPreset(
											PagePeriodPresetLocalStorageKey.EMPLOYEES_DASHBOARD,
										);

										const {
											periodStartDate,
											periodEndDate,
										} = getPeriodDatesByPreset({
											preset,
										});

										const employeesDashboardView = getEmployeesDashboardViewFromLocalStorage();

										return (
											<Redirect
												to={
													generateEmployeesPageUrl({
														periodStartDate,
														periodEndDate,
														view: (
															employeesDashboardView === EmployeesDashboardView.ORIGINAL_VIEW
																? undefined
																: employeesDashboardView
														),
													})
												}
											/>
										);
									}}
								/>

								<ApmRoute
									path={CLONE_PACKAGE_ROUTE_PATH}
									component={(props) => {
										return (
											<PackageEditPage
												{...props}
												isClone={true}
											/>
										);
									}}
								/>
								<ApmRoute
									path={EDIT_PACKAGE_ROUTE_PATH}
									component={PackageEditPage}
								/>
								<ApmRoute
									path={NEW_PACKAGE_ROUTE_PATH}
									component={PackageEditPage}
								/>

								<ApmRoute
									exact={true}
									path={
										generatePackagesPageUrl({
											projectId: PROJECT_ID_PATH_PATTERN,
										})
									}
									render={({
										match: {
											params: {
												projectId,
											},
										},
									}) => {
										return (
											<Redirect
												to={
													generatePackagesPageUrl({
														projectId,
														periodStartDate: packagesPeriod.fromDate,
														periodEndDate: packagesPeriod.toDate,
													})
												}
											/>
										);
									}}
								/>
								<ApmRoute
									path={PACKAGES_DASHBOARD_ROUTE_PATH}
									component={(props) => {
										const {
											fromDate,
											toDate,
											projectId,
										} = props.match.params;

										const sampleDate = parseISO(fromDate);

										const monthStartDate = startOfMonth(sampleDate);
										const monthStartDateString = toDateString(monthStartDate);

										const monthEndDate = endOfMonth(sampleDate);
										const monthEndDateString = toDateString(monthEndDate);

										if (
											fromDate !== monthStartDateString
											|| toDate !== monthEndDateString
										) {
											return (
												<Redirect
													to={
														generatePackagesPageUrl({
															projectId,
															periodStartDate: monthStartDateString,
															periodEndDate: monthEndDateString,
														})
													}
												/>
											);
										}

										localStorage.setItem(
											"epmtime.package_period",
											JSON.stringify({
												fromDate,
												toDate,
											}),
										);

										return (
											<PackageListPage
												{...props}
												from={fromDate}
												to={toDate}
											/>
										);
									}}
								/>

								<ApmRoute
									path={ISSUE_MAPPING_ROUTE_PATH}
									component={IssueMappingPage}
								/>

								<ApmRoute
									path={TMS_LOG_ROUTE_PATH}
									component={TMSLogPage}
								/>
								<ApmRoute
									component={() => {
										return (
											<PageNotFound/>
										);
									}}
								/>
							</Switch>
						</Suspense>
					</main>
				</div>
			</ErrorBoundary>
		);
	}
}

/**
 * @param {import("store").RootState} state
 */
const mapStateToProps = (state) => {
	return {
		userId: getUserId(state),
	};
};

const mapDispatchToProps = {
	hideSpinner,
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(App),
);
